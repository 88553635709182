import React, { useState } from "react";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  Menu,
  MenuItem,
  // SubMenu
} from "react-pro-sidebar";

import "./topNavigation.css";
import {
  ROUTES,
  MAIN_LOGO,
  sidebarNavItems,
  PROFILE_IMAGE,
} from "../../contants";
import LogoutModal from "../../components/auth/logout/logout";
import { loadData } from "../../utils/localStorage";
import BreadCrumb from "../../commonComponents/breadcrumb/breadcrum";


const TopNavigation = (props) => {
  const [logoutModal, setLogoutModal] = useState(false);
  const [sidebarHide, setSidebarHide] = useState(false);

  const userData = JSON.parse(loadData("user"));
  // const permissionPolicy = userData.role_permission.permission_policy;
  const theme = "light";

  const handleLogout = () => {
    setLogoutModal(true);
  };
  const handleCloseLogoutModal = () => setLogoutModal(false);

  // hex to rgba converter
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#293659",
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#293659",
        hover: {
          backgroundColor: "#fbb615",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, 1)
          : "transparent",
    }),
    button: {
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          0.8
        ),
        color: themes[theme].menu.hover.color,
        fontWeight: "600",
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const handleSidebarShow = () => setSidebarHide(true);
  const handleSidebarClose = () => setSidebarHide(false);

  return (
    <>
      {LogoutModal({ logoutModal, handleCloseLogoutModal })}
      <div className="row navigation-main-container">
        <div className="col-2 navigation-sidebar">
          <div onClick={handleSidebarShow}>
            <i className="bi bi-list"></i>
          </div>
        </div>
        <div className="col-4 navigation-heading">
          {/* {props.activeItem} */}
          <BreadCrumb crumbList={props.crumbList ? props.crumbList : []} />
        </div>
        <div className="col navigation-profile">
          <div className="row">
            <div className="col navigation-text-div">
              <h6 className="navigation-profile-text">
                {userData?.first_name} {userData?.last_name}
              </h6>
              <h6 className="navigation-profile-text-email">
                {userData?.email}
              </h6>
            </div>
            <div className="col-2 navigation-profile-img-div">
              <img
                src={
                  userData?.profile_image
                    ? userData.profile_image
                    : PROFILE_IMAGE
                }
                alt="user profile"
                width="45px"
                className="navigation-profile-img"
              />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={sidebarHide} onHide={handleSidebarClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              src={MAIN_LOGO}
              width="121px"
              height="44px"
              className="d-inline-block align-top navbar-logo"
              alt="FRS logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <label
              style={{
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: "600",
                opacity: 0.7,
                letterSpacing: "0.5px",
              }}
            >
              General
            </label>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link to={ROUTES.DASHBOARD} />}
              icon={<i className="bi bi-bar-chart-fill"></i>}
              style={
                props.activeItem === sidebarNavItems.dashboard
                  ? {
                      backgroundColor: hexToRgba(
                        themes[theme].menu.hover.backgroundColor,
                        0.8
                      ),
                    }
                  : {}
              }
            >
              {sidebarNavItems.dashboard}
            </MenuItem>
          </Menu>
          <div style={{ padding: "0 24px", marginBottom: "8px", marginTop: "20px" }}>
            <label style={{fontSize: "12px", lineHeight: "18px", fontWeight: "600", opacity: 0.7, letterSpacing: "0.5px"}}>
              Referrals
            </label>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link to={ROUTES.REFERRALS} />}
              icon={<i className="bi bi-balloon-fill"></i>}
              style={props.activeItem === sidebarNavItems.referrals ? { backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 0.8) } : {}}
            >
              {sidebarNavItems.referrals}
            </MenuItem>
          </Menu>

          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link onClick={handleLogout} />}
              icon={<i className="bi bi-power"></i>}
            >
              Logout
            </MenuItem>
          </Menu>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TopNavigation;
