import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Placeholder from 'react-bootstrap/Placeholder';

import "./referralDetails.css";
import { sidebarNavItems, ROUTES, CRUMB_LIST } from "../../../contants";
import SideBar from "../../../commonComponents/sidebar/sidebar";
import { clearData, loadData } from '../../../utils/localStorage';
import TopNavigation from "../../../commonComponents/topNavigation/topNavigation";
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';
import { getReferralDetailsService, ReferralLeadsListService } from '../../../modules/services';
import { titleCase } from '../../../utils/utils';
import AdvancedTable from '../../../commonComponents/advancedTable/advancedTable';
import SkeletonLoading from '../../../commonComponents/skeletonLoading/skeletonLoading';


const ReferralDetails = (props) => {
    const navigate = useNavigate();
    const [isLoadingList, setIsLoadingList] = useState(true);
    const [isLoadingDetails, setIsLoadingDetails] = useState(true);
    const [data, setData] = useState({});
    const [leadList, setLeadList] = useState([]);
    const [leadMetaData, setLeadMetaData] = useState({})
    const permissionPolicy = JSON.parse(loadData("user")).role_permission.permission_policy;
    let { referralId } = useParams();

    const breadCrumbList = [
        CRUMB_LIST.dashboard,
        CRUMB_LIST.referralList,
        { ...CRUMB_LIST.referralDetails, active: true, link: ROUTES.REFERRAL_DETAILS + "/" + referralId }
    ]

    const getLeadListData = (pageNo) => {
        (async () => {
            const responseData = await ReferralLeadsListService({pagination: true, referral: referralId, page: pageNo});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setLeadList([...leadList, ...responseData.data[0].results]);

                setLeadMetaData(responseData.data[0].links);
                setIsLoadingList(false);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoadingList(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }
    useEffect(() => { 

        (async () => {
            const responseData = await getReferralDetailsService(referralId, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoadingDetails(false);
                setData(responseData.data);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoadingDetails(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
        getLeadListData(1);
    }, []);

    const handleLoadMore = () => { 
        if (leadMetaData.next) {
            setIsLoadingList(true);
            getLeadListData(leadMetaData.current + 1);
        }
    }

    const handleEditReferral = () => {
        navigate(ROUTES.UPDATE_REFERRAL + "/" + referralId);
    }

    const handleLeadDetails = (rowId) => {
        navigate(ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.LEAD_DETAILS + "/" + rowId);
    }

    const handleAddLead = () => {
        navigate(ROUTES.ADD_LEAD + "/" + referralId);
    }

    const tableColumns = [
        {
            accessor: (row, index) => index + 1,
            Header: 'S. N.',
            minWidth: "50px"
        },
        {
            accessor: "name",
            Header: 'Name',
            onClick: true,
            minWidth: "150px"
        },
        {
            accessor: "designation",
            Header: 'Designation',
            minWidth: "150px"
        },
        {
            accessor: 'organisation_name',
            Header: 'Organisation',
            minWidth: 200,
        },
        {
            accessor: 'mobile',
            Header: 'Mobile',
            minWidth: 150,
        },
        {
            accessor: 'email',
            Header: 'Email',
            minWidth: 170,
        },
        {
            accessor: prop => prop.earned_amount ? "Rs." + prop.earned_amount : "N/A",
            Header: 'Earned Amount',
            minWidth: 150,
        },
        {
            accessor: props => <b style={
                props.status_type_name === "OPEN"
                    ?
                    { color: "#293659" }
                    :
                    props.status_type_name === "ON_GOING"
                        ?
                        { color: "#f3a921" }
                        :
                        props.status_type_name === "HOLD"
                            ?
                            { color: "#c7c423" }
                            :
                            props.status_type_name === "LOST"
                                ?
                                { color: "#c72323" }
                                :
                                props.status_type_name === "WON"
                                ?
                                    { color: "green" }
                                    :
                                    { color: "blank" }
            }>{props.status_name}</b>,
            Header: 'Status',
            minWidth: 150,
        },
    ];

    return (
        <div className='referral-details-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.referrals} />
            <div className='referral-details-side-container'>
                <TopNavigation activeItem={sidebarNavItems.referrals} crumbList={breadCrumbList} />
                
                <div className='row referral-details-filter-container'>
                <div className='referral-details-filter-inner-div'>
                        {
                            permissionPolicy.referral.can_update
                                &&
                                <PrimaryButton
                                    text="Update Referral"
                                    isLoading={isLoadingList}
                                    onClick={handleEditReferral}
                                    customStyle={{width: "150px"}}
                                />
                        }
                    </div>
                </div>
                
                <div className='referral-details-main-container'>
                    <div className='row'>
                        <div className='col'>
                        <h5>Referral Details</h5>
                        </div>
                        <div className='col referral-details-header-right'>
                            
                        </div>
                    </div>
                    
                    <hr className="referral-details-border" />
                    <div className='row'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>First Name</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.first_name ? data.first_name :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Email</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.email ? data.email : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Gender</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.gender ? titleCase(data.gender) : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Pincode</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.pincode ? data.pincode : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>State</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.state ? data.state : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Created At</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.date_joined ? new Date(data.date_joined).toLocaleString() :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Earned Amount</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.earned_amount ? "Rs." + data.earned_amount :<span>N/A</span>}</label>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Last Name</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.last_name ? data.last_name :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Date of Birth</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.dob ? data.dob :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Phone number</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.phone ? data.phone :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>City</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.city ? data.city :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>Country</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.country ? data.country :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='referral-details-label'>User Status</label>
                                </div>
                                <div className='col'>
                                    <label className='referral-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.status ? titleCase(data.status) :<span>N/A</span>}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='referral-details-main-container'>
                    <div className='row'>
                        <div className='col'>
                        <h5>Leads List</h5>
                        </div>
                        <div className='col referral-details-header-right'>
                            <PrimaryButton
                                text="Add Lead"
                                isLoading={isLoadingList}
                                onClick={handleAddLead}
                            />
                        </div>
                    </div>
                    
                    <hr className="referral-details-border" />
                    <AdvancedTable columns={tableColumns} data={[...leadList]} isLoading={isLoadingList} handleLoadMore={handleLoadMore} onClickDetails={handleLeadDetails} rowCount={leadMetaData.count} />
                </div>
            </div>
        </div>
    );
}


export default ReferralDetails;
