import axios from 'axios';
import { toast } from 'react-toastify';

import * as url from "../../utils/urls";
import axiosInstance from '../../utils/axios';
import { loadData } from '../../utils/localStorage';
import { ERROR, SERVER_ERROR } from "../../contants/constants";


const historyId = JSON.parse(loadData("user"))?.history_id;


export const loginService = async (data, queryParams) => {

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return axios.post(`${url.getUserLoggedInUrl}?${queryParamsList}`, data)
  }
  catch (error) {
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
};

export const forgotPasswordService = async (data, queryParams) => {
  
  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return axios.post(`${url.getForgotPasswordUrl}?${queryParamsList}`, data)
  }
  catch (error) {
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
};

export const resetPasswordService = (data) => {

  return axios.patch(
    `${url.getResetPasswordUrl}`,
    data
  )
};


export const getReferralsListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getReferralListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}


export const getCountryListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getCountryListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const getStateListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getStateListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const getCityListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getCityListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const saveReferralUserService = async (data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.post(`${url.saveReferraltUrl}?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const updateReferralUserService = async (referralId, data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.patch(`${url.updateReferraltUrl}/${referralId}/?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const saveReferralLeadService = async (data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.post(`${url.saveReferralLeadUrl}?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const getReferralDetailsService = async (referralId, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.ReferralDetailsUrl}/${referralId}/?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const getReferralLeadDetailsService = async (leadId, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getreferralLeadDetailsUrl}/${leadId}/?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const ReferralLeadsListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.ReferralLeadListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const LeadStatusListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.getLeadStatusUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const LeadTimelineListService = async (queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.get(`${url.LeadTimelineListUrl}?${queryParamsList}`)
      .then((response) => {
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const updateLeadDetailsService = async (leadId, data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.patch(`${url.updateLeadDetailsUrl}/${leadId}/?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const AddLeadCommentService = async (data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.post(`${url.saveLeadCommentUrl}?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}

export const AddLeadPaymentService = async (data, queryParams) => { 
  //saving login history id.
  queryParams["history_id"] = historyId;

  let queryParamsList = "";
  Object.keys(queryParams).map((key) => queryParamsList += "&" + key + "=" + queryParams[key]);

  try {
    return await axiosInstance.post(`${url.saveLeadPaymentUrl}?${queryParamsList}`, data)
      .then((response) => {
        toast.success(response.data.message[0], {
          position: toast.POSITION.TOP_RIGHT,
        });
        return response.data;

      }).catch((error) => {
        const data = error.response.data;
        toast.error(data.message[0]);
        if (data.status_code === 401) {
          return ERROR;
        }
        return data;
      })
    
  } catch (error) { 
    console.log(error);
    toast.error(SERVER_ERROR);
    return ERROR;
  }
}
