import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useForm } from "react-hook-form";
import { z  } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Form from 'react-bootstrap/Form';

import "./leadDetails.css";
import { sidebarNavItems, ROUTES, PROFILE_IMAGE, MESSAGE, CRUMB_LIST, leadPaymentOptionList } from "../../../contants";
import SideBar from "../../../commonComponents/sidebar/sidebar";
import { clearData } from '../../../utils/localStorage';
import TopNavigation from "../../../commonComponents/topNavigation/topNavigation";
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';
import InputTextArea from '../../../commonComponents/inputTextArea/inputTextArea';
import { getReferralLeadDetailsService, LeadStatusListService, updateLeadDetailsService, LeadTimelineListService, AddLeadCommentService, AddLeadPaymentService } from '../../../modules/services';
import FilterDropdown from "../../../commonComponents/filterDropdown/filterDropdown";
import InputField from '../../../commonComponents/inputField/inputField';
import InputDropdown from '../../../commonComponents/dropdown/dropdown';
import SkeletonLoading from '../../../commonComponents/skeletonLoading/skeletonLoading';


const LeadDetails = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDetails, setIsLoadingDetails] = useState(true);
    const [data, setData] = useState({});
    const [timelineList, setTimelineList] = useState([]);
    const [leadStatus, setLeadStatus] = useState([]);
    const [commentModal, setCommentModal] = useState(false);
    const [amountModal, setAmountModal] = useState(false);
    let { referralId, leadId } = useParams();

    const breadCrumbList = [
        CRUMB_LIST.dashboard,
        CRUMB_LIST.referralList,
        { ...CRUMB_LIST.referralDetails, link: ROUTES.REFERRAL_DETAILS + "/" + referralId },
        { ...CRUMB_LIST.leadDetails, link:  ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.LEAD_DETAILS + "/" + leadId, active: true }
    ]

    const loginSchema = z.object({
        //comment
        comment: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Comment") }),
        //timeline_type
        timeline_type: z.string().default("COMMENT"),
        //lead
        lead: z.string().default(leadId)
    });
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });

    const Amountchema = z.object({
        //comment
        comment: z.string(),
        //amount
        amount: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Amount") }).refine(value => {
            return /^\d+$/.test(value);
        }, MESSAGE.FIELD_INVALID.replace("{0}", "Amount")),
        //payment_type
        payment_type: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Payment Type") }),
        //lead
        lead: z.string().default(leadId)
    });
    const { register: amountRegister, control: amountControl, handleSubmit: amounthandleSubmit, setValue: amountSetValue, formState: { errors: amountErrors } } = useForm({
        resolver: zodResolver(Amountchema)
    });

    const renderDropdownListData = (listData, label, value, id) => {
        return(listData.map(data => ({label: data[label], value: data[value], id: data[id]})))
    }

    const getLeadDetails = () => {
        (async () => {
            const responseData = await getReferralLeadDetailsService(leadId, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoadingDetails(false);
                setData(responseData.data);
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoadingDetails(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }
    const getTimelineList = () => {
        (async () => {
            const responseData = await LeadTimelineListService({lead: leadId, pagination: false});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                setTimelineList(responseData.data);
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }
    useEffect(() => {

        getLeadDetails();
        getTimelineList();

        (async () => {
            const responseData = await LeadStatusListService({pagination: false});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                const leadStatusList = renderDropdownListData(responseData.data, "name", "id", "id");
                setLeadStatus(leadStatusList);
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
        
    }, []);

    const handleUpdateLead = () => {
        navigate(ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.UPDATE_LEAD + "/" + leadId);
    }
    
    const handleAddComment = () => {
        setCommentModal(true);
        setValue("comment", "")
    }
    const handleAddAmount = () => {
        setAmountModal(true);
        amountSetValue("amount", "")
        amountSetValue("payment_type", "")
        amountSetValue("comment", "")
    }

    const updateLeadStatus = (data) => {
        (async () => {
            const responseData = await updateLeadDetailsService(leadId, data, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                setData(responseData.data);
                getTimelineList();
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }
    const handleStatusChange = (status) => {
        setIsLoading(true);
        const statusData = {
            status: status.value
        }
        updateLeadStatus(statusData);
    }

    const handleAddCommentSubmission = (data) => {
        setIsLoading(true);
        (async () => {
            const responseData = await AddLeadCommentService(data, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                setCommentModal(false);
                getTimelineList();
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }

    const handleAddAmountSubmission = (data) => {
        setIsLoading(true);
        data = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === '' ? null : value])
        );
        (async () => {
            const responseData = await AddLeadPaymentService(data, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                setAmountModal(false);
                getTimelineList();
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }

    const handleCommentModalClose = () => setCommentModal(false);
    const handleAmountModalClose = () => setAmountModal(false);

    const AddCommentModal = () => {
        return (
            <Modal show={commentModal} onHide={handleCommentModalClose} size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Form onSubmit={handleSubmit(handleAddCommentSubmission)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingBottom: "30px"}}>
                    
                        <div className='row'>
                            <div className='col-sm'>
                                <InputTextArea
                                    inputKey="comment"
                                    placeholder="Comment"
                                    formRegister={register}
                                    errorMessage={errors.comment?.message}
                                />
                            </div>
                        </div>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <PrimaryButton text="Add Comment" type={"submit"} isLoading={isLoading} />
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    const AddAmountModal = () => {
        return (
            <Modal show={amountModal} onHide={handleAmountModalClose} size="md" aria-labelledby="example-modal-sizes-title-lg">
                <Form onSubmit={amounthandleSubmit(handleAddAmountSubmission)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Amount</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{paddingBottom: "30px"}}>
                    
                        
                        <div className='row'>
                            <div className='col-sm'>
                                <InputField
                                    inputKey="amount"
                                    placeholder="Amount"
                                    formRegister={amountRegister}
                                    errorMessage={amountErrors.amount?.message}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm'>
                                <InputDropdown
                                    inputKey="payment_type"
                                    itemList={leadPaymentOptionList}
                                    placeholder="Payment Type"
                                    defaultValue={""}
                                    errorMessage={amountErrors.payment_type?.message}
                                    formRegister={amountControl}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm'>
                                <InputTextArea
                                    inputKey="comment"
                                    placeholder="Comment"
                                    formRegister={amountRegister}
                                    errorMessage={amountErrors.comment?.message}
                                />
                            </div>
                        </div>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <PrimaryButton text="Add Comment" type={"submit"} isLoading={isLoading} />
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }

    const renderStatusChange = () => {
        return (
            <FilterDropdown
                label="Status"
                data={{ label: data.status_name, value: data.status }}
                inputId="lead_status"
                listItems={leadStatus}
                onChangeHandler={handleStatusChange}
                isShowLabel={false}
            />
        );
    }

    return (
        <div className='lead-details-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.referrals} />
            <div className='lead-details-side-container'>
                <TopNavigation activeItem={sidebarNavItems.referrals} crumbList={breadCrumbList} />
                {AddCommentModal()}
                {AddAmountModal()}
                
                <div className='row lead-details-filter-container'>
                <div className='lead-details-filter-inner-div'>
                    </div>
                </div>
                
                <div className='lead-details-main-container'>
                    <div className='row'>
                        <div className='col'>
                        <h5>Lead Details</h5>
                        </div>
                        <div className='col lead-details-header-right'>
                            {
                                data.status_type_name === "WON"
                                    &&
                                    <PrimaryButton
                                        text="Add Amount"
                                        isLoading={isLoading}
                                        onClick={handleAddAmount}
                                        customStyle={{marginRight: "10px"}}
                                    />
                            }
                            <PrimaryButton
                                text="Update Lead"
                                isLoading={isLoading}
                                onClick={handleUpdateLead}
                            />
                        </div>
                    </div>
                    
                    <hr className="lead-details-border" />
                    <div className='row'>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Name</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.name ? data.name :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Email</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.email ? data.email : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Mobile number</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.mobile ? data.mobile : <span>N/A</span>} {data.mentor_last_name}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Location</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.location ? data.location :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Created At</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.created_at ? new Date(data.created_at).toLocaleString() :<span>N/A</span>}</label>
                                </div>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Designation</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.designation ? data.designation :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Organisation Name</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.organisation_name ? data.organisation_name :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Whatsapp number</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.whatsapp_no ? data.whatsapp_no :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>User Status</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.status_name ? renderStatusChange() :<span>N/A</span>}</label>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <label className='lead-details-label'>Earned Amount</label>
                                </div>
                                <div className='col'>
                                    <label className='lead-details-value'>{isLoadingDetails ? <SkeletonLoading customStyle={{width: "150px"}}/> : data.earned_amount ? "Rs." + data.earned_amount :<span>N/A</span>}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='lead-details-main-container'>
                    <div className='row'>
                        <div className='col'>
                        <h5>Timeline</h5>
                        </div>
                        <div className='col lead-details-header-right'>
                            <PrimaryButton
                                text="Add Comment"
                                isLoading={isLoading}
                                onClick={handleAddComment}
                            />
                        </div>
                    </div>
                    
                    <hr className="lead-details-border" />
                    {
                        timelineList.map((timeline, index) => {
                            return (
                                <div key={index}>
                                    {
                                        timeline.timeline_type === "CREATED"
                                            ?
                                            <div className='row lead-details-timeline-tile-div'>
                                                <div className="col-1 navigation-profile-img-div">
                                                    <img
                                                        src={PROFILE_IMAGE}
                                                        alt="user profile"
                                                        width="40px"
                                                        className="navigation-profile-img"
                                                    />
                                                </div>
                                                <div className='col'>
                                                    <b>{timeline.created_by_name}</b> created lead on <b>{new Date(timeline.created_at).toLocaleString()}</b>
                                                </div>
                                            </div>
                                            :
                                            timeline.timeline_type === "STATUS_CHANGED"
                                                ?
                                                <div className='row lead-details-timeline-tile-div'>
                                                    <div className="col-1 navigation-profile-img-div">
                                                        <img
                                                            src={PROFILE_IMAGE}
                                                            alt="user profile"
                                                            width="40px"
                                                            className="navigation-profile-img"
                                                        />
                                                    </div>
                                                    <div className='col'>
                                                        <b>{timeline.created_by_name}</b> changed lead status on <b>{new Date(timeline.created_at).toLocaleString()}</b><br />
                                                        <b>{timeline.from_status_name}</b> -&gt;&gt; <b>{timeline.to_status_name}</b>
                                                    </div>
                                                </div>
                                                :
                                                timeline.timeline_type === "COMMENT"
                                                    ?
                                                    <div className='row lead-details-timeline-tile-div'>
                                                        <div className="col-1 navigation-profile-img-div">
                                                            <img
                                                                src={PROFILE_IMAGE}
                                                                alt="user profile"
                                                                width="40px"
                                                                className="navigation-profile-img"
                                                            />
                                                        </div>
                                                        <div className='col'>
                                                            <b>{timeline.created_by_name}</b> changed lead status on <b>{new Date(timeline.created_at).toLocaleString()}</b><br />
                                                            {timeline.comment}
                                                        </div>
                                                    </div>
                                                    :
                                                    timeline.timeline_type === "PAYMENT_ADDED"
                                                        ?
                                                        <div className='row lead-details-timeline-tile-div'>
                                                            <div className="col-1 navigation-profile-img-div">
                                                                <img
                                                                    src={PROFILE_IMAGE}
                                                                    alt="user profile"
                                                                    width="40px"
                                                                    className="navigation-profile-img"
                                                                />
                                                            </div>
                                                            <div className='col'>
                                                                <b>{timeline.created_by_name}</b> Added Payment of <b>Rs.{timeline.amount}</b> on <b>{new Date(timeline.created_at).toLocaleString()}</b><br />
                                                                {timeline.comment ? timeline.comment : "N/A"}
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>

                                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
}


export default LeadDetails;
