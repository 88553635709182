import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { z  } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Form from 'react-bootstrap/Form';

import "./updateLead.css";
import SideBar from "../../../commonComponents/sidebar/sidebar";
import InputField from '../../../commonComponents/inputField/inputField';
import { sidebarNavItems, MESSAGE, ROUTES, CRUMB_LIST } from "../../../contants";
import TopNavigation from "../../../commonComponents/topNavigation/topNavigation";
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';
import { clearData } from '../../../utils/localStorage';
import { updateLeadDetailsService, getReferralLeadDetailsService } from '../../../modules/services';


const UpdateLead = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    let { referralId, leadId } = useParams();

    const breadCrumbList = [
        CRUMB_LIST.dashboard,
        CRUMB_LIST.referralList,
        { ...CRUMB_LIST.referralDetails, link: ROUTES.REFERRAL_DETAILS + "/" + referralId },
        { ...CRUMB_LIST.leadDetails, link: ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.LEAD_DETAILS + "/" + leadId },
        { ...CRUMB_LIST.leadUpdate, link:  ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.UPDATE_LEAD + "/" + leadId, active: true },
    ]

    const loginSchema = z.object({
        //name
        name: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Name") }),
        //designation
        designation: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Designation") }),
        //organisation_name
        organisation_name: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Organisation Name") }),
        //mobile
        mobile: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Phone number") }).refine(value => {
            return /^\d{10}$/.test(value);
        }, MESSAGE.INVALID_PHONE.replace("{0}", "Phone number")),
        //whatsapp_no
        whatsapp_no: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "WhatsApp number") }).refine(value => {
            return /^\d{10}$/.test(value);
        }, MESSAGE.INVALID_PHONE.replace("{0}", "WhatsApp number")),
        email: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Email")}).email({message: MESSAGE.EMAIL_INVALID}),
        //location
        location: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Location") }),
    });

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });

    const getLeadDetails = () => {
        setIsLoading(true);
        (async () => {
            const responseData = await getReferralLeadDetailsService(leadId, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                const data = Object.fromEntries(
                    Object.entries(responseData.data).map(([key, value]) => [key, value === null ? "" : value])
                );

                setValue("name", data.name);
                setValue("designation", data.designation);
                setValue("organisation_name", data.organisation_name);
                setValue("mobile", data.mobile);
                setValue("whatsapp_no", data.whatsapp_no);
                setValue("email", data.email);
                setValue("location", data.location); 
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }
    useEffect(() => {
        getLeadDetails();
    }, []);

    const handleUpdateLead = (data) => { 
        setIsLoading(true);
        data = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === '' ? null : value])
        );
        (async () => {
            const responseData = await updateLeadDetailsService(leadId, data, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                navigate(ROUTES.REFERRAL_DETAILS + "/" + referralId + ROUTES.LEAD_DETAILS + "/" + leadId);
            }
            else if (responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }

    return (
        <div className='add-lead-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.referrals} />
            <div className='add-lead-side-container'>
                <TopNavigation activeItem={sidebarNavItems.referrals} crumbList={breadCrumbList} />
                
                <div className='row add-lead-filter-container'>
                </div>
                
                <div className='add-lead-main-container'>
                    <Form onSubmit={handleSubmit(handleUpdateLead)}>
                        <div className='row'>
                            <div className='col'>
                            <h5>Update Lead</h5>
                            </div>
                            <div className='col add-lead-header-right'>
                                <PrimaryButton
                                    type={"submit"}
                                    isLoading={isLoading}
                                    text="SAVE"
                                />
                            </div>
                        </div>
                        
                        <hr className="add-lead-border" />
                        <div className='row add-lead-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Name"
                                    fieldType="text"
                                    errorMessage={errors.name?.message}
                                    inputKey="name"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Designation"
                                    fieldType="text"
                                    errorMessage={errors.designation?.message}
                                    inputKey="designation"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-lead-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Organisation Name"
                                    fieldType="text"
                                    errorMessage={errors.organisation_name?.message}
                                    inputKey="organisation_name"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Mobile number"
                                    fieldType="text"
                                    errorMessage={errors.mobile?.message}
                                    inputKey="mobile"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-lead-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="WhatsApp number"
                                    fieldType="text"
                                    errorMessage={errors.whatsapp_no?.message}
                                    inputKey="whatsapp_no"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Email"
                                    fieldType="text"
                                    errorMessage={errors.email?.message}
                                    inputKey="email"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-lead-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    placeholder="Location"
                                    fieldType="text"
                                    errorMessage={errors.location?.message}
                                    inputKey="location"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        
                    </Form>
                </div>
                
            </div>
        </div>
    );
 }


export default UpdateLead;
