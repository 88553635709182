import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { z  } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Form from 'react-bootstrap/Form';

import "./updateReferral.css";
import SideBar from "../../../commonComponents/sidebar/sidebar";
import InputField from '../../../commonComponents/inputField/inputField';
import { sidebarNavItems, MESSAGE, genderList, ROUTES, CRUMB_LIST } from "../../../contants";
import TopNavigation from "../../../commonComponents/topNavigation/topNavigation";
import PrimaryButton from '../../../commonComponents/primaryButton/primaryButton';
import InputDropdown from '../../../commonComponents/dropdown/dropdown';
import { clearData } from '../../../utils/localStorage';
import { getCountryListService, getStateListService, getCityListService, updateReferralUserService, getReferralDetailsService } from '../../../modules/services';


const UpdateReferral = (props) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const genderOptionList = [...genderList];
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    let { referralId } = useParams();

    const breadCrumbList = [
        CRUMB_LIST.dashboard,
        CRUMB_LIST.referralList,
        { ...CRUMB_LIST.referralDetails, link: ROUTES.REFERRAL_DETAILS + "/" + referralId },
        { ...CRUMB_LIST.referralUpdate, link: ROUTES.UPDATE_REFERRAL + "/" + referralId, active: true }
    ]

    const loginSchema = z.object({
        //first name
        first_name: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "First Name") }),
        //last name
        last_name: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Last Name") }),
        //email
        email: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Email") }).email({ message: MESSAGE.EMAIL_INVALID }),
        //dob
        dob: z.string().refine((value) => {
            if (value === "") {
                return true;
            }
            const date = new Date(value);
            const today = new Date();
            return date < today;
        }, MESSAGE.DOB_INVALID),
        //gender
        gender: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Gender") }),
        //phone
        phone: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Phone number") }).refine(value => {
            return /^[+]{1}(?:[0-9\-\\(\\)\\/.]\s?){6,15}[0-9]{1}$/.test(value) || /^\d{10}$/.test(value);
        }, MESSAGE.INVALID_PHONE.replace("{0}", "Phone number")),
        //pincode
        pincode: z.string().refine(value => {
            return value === "" || /^\d{6}$/.test(value);
        }, MESSAGE.FIELD_INVALID.replace("{0}", "Pincode")),
        //country
        country: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "Country") }),
        //state
        state: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "State") }),
        //city
        city: z.string().min(1, { message: MESSAGE.THIS_REQUEIRED_FIELD.replace("{0}", "City") }),
    });
    const { register, control, handleSubmit, getValues, reset, formState: { errors } } = useForm({
        resolver: zodResolver(loginSchema)
    });

    const getReferralDetails = (countryList) => {
        (async () => {
            const responseData = await getReferralDetailsService(referralId, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                if (responseData.data.country) {
                    const foundCountry = countryList.find((thisCountry) => thisCountry.value === responseData.data.country)
                    getStateDataService(foundCountry.id, true);
                }
                const data = Object.fromEntries(
                    Object.entries(responseData.data).map(([key, value]) => [key, value === null ? "" : value])
                );
                reset(data);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }

    useEffect(() => { 
        getCountryDataService(true);
    }, []);

    const renderDropdownListData = (listData, label, value, id) => {
        return(listData.map(data => ({label: data[label], value: data[value], id: data[id]})))
    }

    const getCityDataService = (state, firstTime=false) => {
        (async () => {
            const responseData = await getCityListService({state: state, pagination: false});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                const cityList = renderDropdownListData(responseData.data, "city", "city", "id");
                setCityList(cityList);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    };

    const getStateDataService = (country, firstTime=false) => {
        (async () => {
            const responseData = await getStateListService({country_id: country, pagination: false});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                const stateList = renderDropdownListData(responseData.data, "name", "name", "id");
                setStateList(stateList);
                if (firstTime) {
                    if (getValues("state")) {
                        const foundState = stateList.find((thisState) => thisState.value === getValues("state"))
                        getCityDataService(foundState.id, true);
                    }
                }
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    };

    const getCountryDataService = (firstTime=false) => {
        (async () => {
            const responseData = await getCountryListService({pagination: false});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                const countryList = renderDropdownListData(responseData.data, "name", "name", "id");
                setCountryList(countryList);
                if (firstTime) {
                    getReferralDetails(countryList);
                }
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    };

    const handleCountryChange = (country) => {
        getStateDataService(countryList.filter(option => option.value === country)[0].id);
    }

    const handleStateChange = (state) => {
        getCityDataService(stateList.filter(option => option.value === state)[0].id);
    }

    
    const handleAddReferralSave = (data) => { 
        setIsLoading(true);
        data = Object.fromEntries(
            Object.entries(data).map(([key, value]) => [key, value === '' ? null : value])
        );
        (async () => {
            const responseData = await updateReferralUserService(referralId, data, {});
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                setIsLoading(false);
                navigate(ROUTES.REFERRAL_DETAILS + "/" + referralId);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    }

    return (
        <div className='add-mentor-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.referrals} />
            <div className='add-mentor-side-container'>
                <TopNavigation activeItem={sidebarNavItems.referrals} crumbList={breadCrumbList} />
                
                <div className='row add-mentor-filter-container'>
                </div>
                
                <div className='add-mentor-main-container'>
                    <Form onSubmit={handleSubmit(handleAddReferralSave)}>
                        <div className='row'>
                            <div className='col'>
                            <h5>Update Referral</h5>
                            </div>
                            <div className='col add-mentor-header-right'>
                                <PrimaryButton
                                    type={"submit"}
                                    isLoading={isLoading}
                                    text="SAVE"
                                />
                            </div>
                        </div>
                        
                        <hr className="add-mentor-border" />
                        <div className='row add-mentor-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="First name"
                                    fieldType="text"
                                    errorMessage={errors.first_name?.message}
                                    inputKey="first_name"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="Last Name"
                                    fieldType="text"
                                    errorMessage={errors.last_name?.message}
                                    inputKey="last_name"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-mentor-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="Email"
                                    fieldType="text"
                                    errorMessage={errors.email?.message}
                                    inputKey="email"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="Date of Birth"
                                    fieldType="date"
                                    errorMessage={errors.dob?.message}
                                    inputKey="dob"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-mentor-input-row'>
                            <div className='col-sm'>
                                <InputDropdown
                                    inputKey="gender"
                                    iconClass="bi-person-circle"
                                    itemList={genderOptionList}
                                    maxWidth="400px"
                                    placeholder="Gender"
                                    defaultValue={""}
                                    errorMessage={errors.gender?.message}
                                    formRegister={control}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="Phone number"
                                    fieldType="text"
                                    errorMessage={errors.phone?.message}
                                    inputKey="phone"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                        </div>
                        <div className='row add-mentor-input-row'>
                            <div className='col-sm'>
                                <InputField
                                    iconClass="bi-person-fill"
                                    placeholder="Pincode"
                                    fieldType="text"
                                    errorMessage={errors.pincode?.message}
                                    inputKey="pincode"
                                    maxWidth="400px"
                                    formRegister={register}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputDropdown
                                    inputKey="country"
                                    iconClass="bi-person-circle"
                                    itemList={countryList}
                                    maxWidth="400px"
                                    placeholder="Country"
                                    defaultValue={""}
                                    errorMessage={errors.country?.message}
                                    formRegister={control}
                                    onDropdownChange={handleCountryChange}
                                />
                            </div>
                        </div>
                        <div className='row add-mentor-input-row'>
                        <div className='col-sm'>
                                <InputDropdown
                                    inputKey="state"
                                    iconClass="bi-person-circle"
                                    itemList={stateList}
                                    maxWidth="400px"
                                    placeholder="State"
                                    defaultValue={""}
                                    errorMessage={errors.state?.message}
                                    formRegister={control}
                                    onDropdownChange={handleStateChange}
                                />
                            </div>
                            <div className='col-sm'>
                                <InputDropdown
                                    inputKey="city"
                                    iconClass="bi-person-circle"
                                    itemList={cityList}
                                    maxWidth="400px"
                                    placeholder="City"
                                    defaultValue={""}
                                    errorMessage={errors.city?.message}
                                    formRegister={control}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
                
            </div>
        </div>
    );
 }


export default UpdateReferral;
