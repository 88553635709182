import axios from "axios";
import { loadData } from "./localStorage";


const axiosInstance = axios.create({

  transformResponse: [function (data) {
    // Do whatever you want to transform the data
    return JSON.parse(data);
  }],

  headers: {
    "Authorization": `Bearer ${loadData("access")}`
  },
  
  params: {
    platform: "web",
  },

});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      // const result = await memRefreshTokenService({});

      // if (result?.accessToken) {
      //   config.headers = {
      //     ...config.headers,
      //     authorization: `Bearer ${result?.accessToken}`,
      //   };
      // }

      

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
