import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import "./filterDropdown.css";

const FilterDropdown = ({label, data, inputId, listItems, onChangeHandler, isDisabled, isShowLabel=true}) => { 
    return (
        <Dropdown as={ButtonGroup}>
            {
                isShowLabel
                &&
                <label className='filter-dropdown-label'>{label}:&nbsp; </label>
            }
            <label className='filter-dropdown-value'>{data.label ? data.label : "On Option"}</label>
            <Dropdown.Toggle disabled={isDisabled} split variant="success" id={inputId} className='filter-dropdown-toggle' />
            <Dropdown.Menu style={{maxHeight: "500px", overflowY: "scroll"}}>
                {
                    listItems.map((data) => { 
                        return (
                            <Dropdown.Item key={data.value} onClick={() => onChangeHandler(data)}>{data.label}</Dropdown.Item>
                        );
                    })
                }
                
            </Dropdown.Menu>
        </Dropdown>
    );
}


export default FilterDropdown;
