import React from 'react'

import "./skeletonLoading.css";


const SkeletonLoading = ({customStyle}) => {
  return (
      <div className='skeleton-loading' style={{ ...customStyle }}>
        &nbsp;
    </div>
  )
}

export default SkeletonLoading;
