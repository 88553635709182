import React, { useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  // SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import "./sidebar.css";
// import { loadData } from "../../utils/localStorage";
import LogoutModal from "../../components/auth/logout/logout";
import {
  MAIN_LOGO_SHIELD,
  MAIN_LOGO,
  sidebarNavItems,
  ROUTES,
} from "../../contants";

const SideBar = ({activeItem}) => {
  // const permissionPolicy = JSON.parse(loadData("user")).role_permission?.permission_policy;
  const [logoutModal, setLogoutModal] = useState(false);
  const [collapsed, setCollapsed] = useState();
  const [toggled, setToggled] = useState(false);
  const theme = "light";

  const changeCollapse = () => {
    const newCollapsed = !collapsed;
    setCollapsed(newCollapsed);
  }

  const handleLogout = () => {
    setLogoutModal(true);
  }
  const handleCloseLogoutModal = () => setLogoutModal(false);

  // hex to rgba converter
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#293659",
      },
      menu: {
        menuContent: "#fbfcfd",
        icon: "#293659",
        hover: {
          backgroundColor: "#fbb615",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: "#0b2948",
        color: "#8ba1b7",
      },
      menu: {
        menuContent: "#082440",
        icon: "#59d0ff",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, !collapsed ? 0.4 : 1)
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 0.8),
        color: themes[theme].menu.hover.color,
        fontWeight: "600"
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  document.documentElement.style.setProperty('--sidebar-width', collapsed ? "140px" : "320px");


  return (
    <Sidebar collapsed={collapsed} toggled={toggled} onBackdropClick={() => setToggled(false)} breakPoint="md" backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}>
      {LogoutModal({logoutModal, handleCloseLogoutModal})}
      
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ marginBottom: "28px", marginTop: "16px" }}>
          {collapsed ? (
            <img
              src={MAIN_LOGO_SHIELD}
              width="44px"
              height="44px"
              className="d-inline-block align-top navbar-logo"
              alt="FRS logo"
              style={{ marginLeft: "15px", transition: "width 0.3s" }}
            />
          ) : (
            <img
              src={MAIN_LOGO}
              width="181px"
              // height="104px"
              className="d-inline-block align-top navbar-logo"
              alt="FRS logo"
              style={{ marginLeft: "25px", transition: "width 0.3s" }}
            />
          )}
        </div>
        <div style={{ flex: 1, marginBottom: "32px" }}>
          <div style={{ padding: "0 24px", marginBottom: "8px" }}>
            <label style={{fontSize: "12px", lineHeight: "18px", fontWeight: "600", opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px"}}>
              General
            </label>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link to={ROUTES.DASHBOARD} />}
              icon={<i className="bi bi-bar-chart-fill"></i>}
              style={activeItem === sidebarNavItems.dashboard ? { backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 0.8) } : {}}
            >
              {sidebarNavItems.dashboard}
            </MenuItem>
          </Menu>

          <div style={{ padding: "0 24px", marginBottom: "8px", marginTop: "20px" }}>
            <label style={{fontSize: "12px", lineHeight: "18px", fontWeight: "600", opacity: collapsed ? 0 : 0.7, letterSpacing: "0.5px"}}>
              Referrals
            </label>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              component={<Link to={ROUTES.REFERRALS} />}
              icon={<i className="bi bi-balloon-fill"></i>}
              style={activeItem === sidebarNavItems.referrals ? { backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, 0.8) } : {}}
            >
              {sidebarNavItems.referrals}
            </MenuItem>
          </Menu>
        </div>

        <Menu menuItemStyles={menuItemStyles}>
          <MenuItem icon={<i className="bi bi-power"></i>} onClick={handleLogout}>
            Logout
          </MenuItem>
          <MenuItem icon={<i className="bi bi-arrow-left-right"></i>} onClick={changeCollapse}>
            Collapse
          </MenuItem>
        </Menu>
      </div>
    </Sidebar>
  );
};

export default SideBar;
