let apiVersion = process.env.REACT_APP_API_URL;

//Authorization URL
export const getUserLoggedInUrl = `${apiVersion}/users/login`;
export const getForgotPasswordUrl = `${apiVersion}/users/forgotPasswordRequest`;
export const getResetPasswordUrl = `${apiVersion}/users/passwordResetRequest`;
export const getChangePasswordUrl = `${apiVersion}/users/changePassword`;
export const getAccessTokenUrl = `${apiVersion}/users/generateToken`;

export const getReferralListUrl = `${apiVersion}/users/referralUserList`;
export const saveReferraltUrl = `${apiVersion}/users/addReferralUser`;
export const ReferralDetailsUrl = `${apiVersion}/users/getReferralUserDetails`;
export const updateReferraltUrl = `${apiVersion}/users/updateReferralUserDetails`;

export const ReferralLeadListUrl = `${apiVersion}/referral_leads/referralLeadList`;
export const saveReferralLeadUrl = `${apiVersion}/referral_leads/addReferralLead`;
export const getreferralLeadDetailsUrl = `${apiVersion}/referral_leads/leadDetail`;
export const getLeadStatusUrl = `${apiVersion}/referral_leads/leadStatusList`;
export const updateLeadDetailsUrl = `${apiVersion}/referral_leads/updateLeadDetail`;
export const LeadTimelineListUrl = `${apiVersion}/referral_leads/leadTimelineList`;
export const saveLeadCommentUrl = `${apiVersion}/referral_leads/addLeadComment`;
export const saveLeadPaymentUrl = `${apiVersion}/referral_leads/addLeadPayment`;

export const getCountryListUrl = `${apiVersion}/common/getCountry`;
export const getStateListUrl = `${apiVersion}/common/getState`;
export const getCityListUrl = `${apiVersion}/common/getCity`;
