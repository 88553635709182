export const MESSAGE = {
    EMAIL_INVALID: "Please enter valid email address.",
    THIS_REQUEIRED_FIELD: "{0} field is required.",
    CONFIRM_PASSWORD_INVALID: "Confirm Password should be same as Password",
    DOB_INVALID: "Please enter valid Date of Birth.",
    SPACE_NOT_ALLOWED: "Spaces are not allowed.",
    FIELD_INVALID: "Invalid {0} format.",
    INVALID_PHONE: "Invalid {0} format, like: +91 9090909090"

    // NEW_PASSWORD_INVALID: "Passwords must contain at least eight characters, including special character, uppercase, lowercase letters and numbers.",

    // FIRST_NAME_SPACE_INVALID: "First name can not have spaces.",
    // LAST_NAME_SPACE_INVALID: "Last name can not have spaces.",

    // PHONE_INVALID: "Phone number must have 10 digits.",
    // PINCODE_INVALID: "Pincode must have 6 digits.",
    // DATE_INVALID: "Date format must be yyyy-dd-mm",
    // GENDER_INVALID: "Gender must be MALE, FEMALE",

    // CSV_ROW_INVALID: " at row number ",
}
