export const ROUTES = {
    LOGIN: "/login",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",

    DASHBOARD: "/dashboard",

    REFERRALS: "/referrals",
    ADD_REFERRALS: "/add-referrals",
    REFERRAL_DETAILS: "/referral-details",
    UPDATE_REFERRAL: "/update-referral",

    ADD_LEAD: "/add-lead",
    LEAD_DETAILS: "/lead-details",
    UPDATE_LEAD: "/update-lead"
}
