import Placeholder from 'react-bootstrap/Placeholder';
import React from "react";
import { useTable, useResizeColumns } from "react-table";

import "./advancedTable.css";
import { DATA_NOT_FOUND } from '../../contants';
import SkeletonLoading from '../skeletonLoading/skeletonLoading';


const AdvancedTable = ({columns, data, isLoading, handleLoadMore, onClickDetails, rowCount}) => {

    const table = useTable({
        columns: columns,
        data: data
    }, useResizeColumns)
    
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;
    console.log(rowCount)

    const handleScroll = event => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && !isLoading) {
            handleLoadMore();
        }
    };

    return (
        <div className="advanced-table-main-div" onScroll={handleScroll}>
            <table {...getTableProps()} className="advanced-table-main">
                <thead>
                    {
                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className="advanced-table-head-row">
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className='advanced-table-head-th' style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} className="advanced-table-body-row">
                                    {row.cells.map((cell) => {
                                        const { onClick } = cell.column; 
                                        return <td
                                            {...cell.getCellProps()}
                                            onClick={onClick ? () => onClickDetails(row.original.id) : () => { }}
                                            className={onClick ? "advanced-table-onclick-td" : "advanced-table-td"}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    })}
                                </tr>
                            )
                        })
                    }
                    { isLoading && [...Array(6)].map((x, i) =>
                        <tr className="advanced-table-body-row" key={i}>
                            {
                                [...Array(columns.length)].map((x, i) => <td key={i}><SkeletonLoading customStyle={{width: "70%"}} /></td>)
                            }
                        </tr>
                    )}
                </tbody>
            </table>
            {
                rowCount === 0 
                    &&
                    <div style={{textAlign: "center"}}>
                        <img src={DATA_NOT_FOUND} alt='no record found' style={{ maxWidth: "500px", marginTop: "5%" }} />
                        <h2>No Data Found</h2>
                    </div>
            }
        </div>
    );
}

export default AdvancedTable;
