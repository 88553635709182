import MainLogo from "../assets/images/FRS New Logo Blue Cropped.png";
import CommingSoonImage from "../assets/images/comming_soon.svg";
import ProfileImage from "../assets/images/profile_image.png";
import MainLogoShield from "../assets/images/FRS_logo_shield.png";
import DataNotFound from "../assets/images/data_not_found.svg";


export const MAIN_LOGO = MainLogo;
export const COMMING_SOON_IMAGE = CommingSoonImage;
export const PROFILE_IMAGE = ProfileImage;
export const MAIN_LOGO_SHIELD = MainLogoShield;
export const DATA_NOT_FOUND = DataNotFound;
