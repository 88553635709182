import React, { useState } from "react";

import "./inputTextArea.css";


const InputTextArea = ({ iconClass, iconSize, placeholder, onChange, errorMessage, inputKey, maxWidth, onFocus, onBlur, onKeyUp, formRegister }) => { 
    
    let inputClassNames = "field-input"
    inputClassNames = errorMessage ? inputClassNames + " field-input-error" : inputClassNames

    return (
        <div className="input-field-main-div" style={maxWidth && {maxWidth: maxWidth}}>
            <textarea
                id={inputKey}
                className={inputClassNames}
                placeholder=" "
                style={{padding: "10px", minHeight: "50px", maxHeight: "500px"}}
                {...formRegister(inputKey)}
            />
            
            <label className="did-floating-label">{placeholder}</label>
            <span className="input-field-invalid-text">{errorMessage}</span>
        </div>
    );
} 


export default InputTextArea;
