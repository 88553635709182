import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";

import "./referralList.css";
import { ROUTES, sidebarNavItems, CRUMB_LIST } from '../../../contants';
import { titleCase } from '../../../utils/utils';
import SideBar from "../../../commonComponents/sidebar/sidebar";
import { getReferralsListService } from "../../../modules/services";
import TopNavigation from "../../../commonComponents/topNavigation/topNavigation";
import PrimaryButton from "../../../commonComponents/primaryButton/primaryButton";
import { loadData, clearData } from '../../../utils/localStorage';
import AdvancedTable from '../../../commonComponents/advancedTable/advancedTable';


const ReferralList = () => { 
    const [dataMeta, setDataMeta] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const permissionPolicy = JSON.parse(loadData("user")).role_permission.permission_policy;
    const breadCrumbList = [
        CRUMB_LIST.dashboard,
        { ...CRUMB_LIST.referralList, active: true }
    ];

    const getReferralListData = (pageNo) => {
        setIsLoading(true);
        (async () => {
            const responseData = await getReferralsListService({ page: pageNo });
            if (responseData !== "error" && (responseData.status_code === 200 || responseData.status_code === 201)) {
                
                setDataList([...dataList, ...responseData.data[0].results]);
                    
                setDataMeta(responseData.data[0].links);
                setIsLoading(false);
            }
            else if(responseData !== "error" && (responseData.status_code !== 200 || responseData.status_code !== 201)) {
                setIsLoading(false);
            }
            else {
                clearData();
                navigate(ROUTES.LOGIN);
            }
        })();
    };

    useEffect(() => {
        getReferralListData(1);
    }, []);

    const onClickDetails = (rowId) => {
        navigate(ROUTES.REFERRAL_DETAILS + "/" + rowId);
    }

    const handleLoadMore = () => { 
        if (dataMeta.next) {
            setIsLoading(true);
            getReferralListData(dataMeta.current + 1);
        }
    }

    const handleAddNewReferral = () => { 
        navigate(ROUTES.ADD_REFERRALS);
    }
    
    const tableColumns = [
        {
            accessor: (row, index) => index + 1,
            Header: 'S. N.',
            minWidth: "50px"
        },
        {
            accessor: prop => prop.first_name + " " + prop.last_name,
            Header: 'Full Name',
            onClick: true,
            minWidth: "150px"
        },
        {
            accessor: 'email',
            Header: 'Email',
            minWidth: "150px"
        },
        {
            accessor: 'lead_count',
            Header: 'Lead Count',
            minWidth: "140px"
        },
        {
            accessor: prop => titleCase(prop.status),
            Header: 'Status',
            minWidth: "140px"
        },
        {
            accessor: prop => prop.earned_amount ? "Rs." + prop.earned_amount : "N/A",
            Header: 'Earned Amount',
            maxWidth: "150px"
        },
        {
            accessor: 'action',
            Header: 'Action',
            maxWidth: "150px"
        }
    ];
    

    return (
        <div className='referral-list-container' style={{ display: 'flex' }}>
            <SideBar activeItem={sidebarNavItems.referrals} />
            <div className='referral-list-side-container'>
                <TopNavigation activeItem={sidebarNavItems.referrals} crumbList={breadCrumbList} />
                
                <div className='row referral-list-filter-container'>
                    <div className='referral-list-filter-inner-div'>
                        {
                            permissionPolicy.referral.can_add
                                &&
                                <PrimaryButton
                                    text="Add Referral"
                                    isLoading={isLoading}
                                    onClick={handleAddNewReferral}
                                />
                        }
                        
                    </div>
                </div>
                
                <div className='referral-list-main-container'>
                    <AdvancedTable columns={tableColumns} data={[...dataList]} isLoading={isLoading} handleLoadMore={handleLoadMore} onClickDetails={onClickDetails} rowCount={dataMeta.count} />
                </div>
            </div>
        </div>
    );
}


export default ReferralList;
