import React, { useState } from "react";

import "./inputField.css";


const InputField = ({ iconClass, iconSize, placeholder, fieldType, onChange, errorMessage, inputKey, maxWidth, onFocus, onBlur, onKeyUp, formRegister }) => { 
    
    const [isPassword, setIsPassword] = useState(true);

    const handlePasswordVisible = () => { 
        setIsPassword(!isPassword);
    }
    let inputClassNames = fieldType === "password" ? "field-input input-field-password" : "field-input"
    inputClassNames = errorMessage ? inputClassNames + " field-input-error" : inputClassNames

    return (
        <div className="input-field-main-div" style={maxWidth && {maxWidth: maxWidth}}>
            <input
                id={inputKey}
                className={inputClassNames}
                type={isPassword ? fieldType : "text"}
                placeholder=" "
                {...formRegister(inputKey)}
            />
            {
                fieldType === "password"
                    &&
                    <span class="input-group-prepend">
                        <div class="input-group-text">
                        <i className={
                            isPassword ?
                                "bi bi-eye-fill input-icon"
                                :
                                "bi bi-eye-slash-fill input-icon"
                        } onClick={handlePasswordVisible}></i>
                        </div>
                    </span>
            }
            
            <label className="did-floating-label">{placeholder}</label>
            <span className="input-field-invalid-text">{errorMessage}</span>
        </div>
    );
} 


export default InputField;
