import React from "react";
import Select, { components } from 'react-select';
import { Controller } from "react-hook-form";

import "./dropdown.css";

const { ValueContainer, Placeholder } = components;

const InputDropdown = ({ iconClass, placeholder, defaultValue, onDropdownChange, iconSize, errorMessage, inputKey, maxWidth, itemList, isDisabled, formRegister }) => { 
    
    const CustomValueContainer = ({ children, ...props }) => {
        return (
          <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
              {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
              child && child.type !== Placeholder ? child : null
            )}
          </ValueContainer>
        );
    };
    
    return (
        <div className="input-dropdown-main-div" style={maxWidth && {maxWidth: maxWidth}}>
            <Controller
                name={inputKey}
                control={formRegister}
                defaultValue={defaultValue}
                render={({ field: { onChange, value, ref } }) => (
                    <Select
                        id={inputKey}
                        options={itemList}
                        value={itemList.find((c) => c.value === value)}
                        className="react-select-container"
                        classNamePrefix={errorMessage ? "select-dropdown-error" : "select-dropdown"}
                        placeholder={placeholder}
                        onChange={(e) => {
                            onChange(e.value);
                            onDropdownChange && onDropdownChange(e.value);
                        }}
                        components={{
                            ValueContainer: CustomValueContainer
                        }}
                        styles={{
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflow: "visible"
                            }),
                            placeholder: (provided, state) => ({
                                ...provided,
                                position: "absolute",
                                top: state.hasValue || state.selectProps.inputValue ? "-22px" : "0px",
                                left: "8px",
                                backgroundColor: "white",
                                padding: "2px",
                                transition: "top 0.2s, font-size 0.1s",
                                fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
                                color: "#293659" 
                            })
                          }}
                    />
                )}
            />
            <span className="input-dropdown-invalid-text">{errorMessage}</span>
        </div>
        // <div>
        // <div className="input-dropdown-div" style={maxWidth && {
        //     maxWidth: maxWidth
        // }}>
        //     <div className={
        //         errorMessage !== "" && typeof(errorMessage) !== "undefined" ?
        //             'input-dropdown-control row input-dropdown-invalid'
        //             :
        //             "input-dropdown-control row"
        //     }>
        //         <div className="col-2 input-dropdown-icon-div">
        //                 <i className={"bi " + iconClass + " input-dropdown-icon"} style={iconSize && { fontSize: iconSize }}></i>
        //         </div>
        //             <div className="col">
        //                 <Controller
        //                     name={inputKey}
        //                     control={formRegister}
        //                     defaultValue={defaultValue}
        //                     render={({ field: { onChange, value, ref }}) => (
        //                         <Select
        //                             id={inputKey}
        //                             options={itemList}
        //                             className="react-select-container"
        //                             classNamePrefix="react-select"
        //                             placeholder={placeholder}
        //                             onChange={(e) => {
        //                                 onChange(e.value);
        //                                 onDropdownChange && onDropdownChange(e.value);
        //                             }}
        //                             isDisabled={isDisabled}
        //                             value={itemList.find((c) => c.value === value)}
        //                         />
        //                     )}
        //                 />
                        
                        
        //         </div>
        //     </div>
        //     </div>
        //     <span className="input-dropdown-invalid-text">{errorMessage}</span>
            
        // </div>
    );
}


export default InputDropdown;
