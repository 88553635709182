import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./breadcrum.css";


const BreadCrumb = ({ crumbList }) => {
  const navigate = useNavigate();

  return (
    <ol typeof="BreadcrumbList" aria-label="breadcrumbs" className="breadcrumbs">
      {
        crumbList.map((crumb, index) => (
          <React.Fragment key={index}>
            <li property="itemListElement" typeof="ListItem" className="breadcrumb" >
              <Link className={crumb.active ? "breadcrumb__current" : "breadcrumb__link"} to={crumb.link}>
                {
                  crumb.icon
                  &&
                  <i className={"bi breadcrumb-icon " + crumb.icon} onClick={() => navigate(crumb.link)}></i>
                }
                <span property="name" className={crumb.icon ? "breadcrumbs-label breadcrumbs-label-small-screen" : "breadcrumbs-label"}>{crumb.label}</span>
              </Link>
              <meta property="position" content="1" />
            </li>
            {
              !crumb.active
                &&
                <li className="breadcrumb__separator">/</li>  
            }
            
          </React.Fragment>
        ))
      }
    </ol>
  );
};

export default BreadCrumb;
