import { ROUTES } from "./routes";

export const sidebarNavItems = {
    dashboard: "Dashboard",
    referrals: "Referrals",
}

export const genderList = [
    {
        label: "Male",
        value: "MALE"
    },
    {
        label: "Female",
        value: "FEMALE"
    },
    {
        label: "Others",
        value: "OTHERS"
    },
]

export const genderSimpleList = [
    "MALE", "FEMALE", "Others"
]

export const leadPaymentOptionList = [
    {
        label: "One Time Payment",
        value: "ONE_TIME"
    },
    {
        label: "Periodic Payment",
        value: "MONTHLY_PERIODIC"
    }
]

export const ERROR = "error";
export const SERVER_ERROR = "Server Error";

export const CRUMB_LIST = {
    dashboard: {
        label: "Dashboard",
        link: ROUTES.DASHBOARD,
        icon: "bi-bar-chart-fill"
    },
    referralList: {
        label: "Referrals List",
        link: ROUTES.REFERRALS,
    },
    referralDetails: {
        label: "Referrals Details",
        link: ROUTES.REFERRAL_DETAILS,
    },
    referralUpdate: {
        label: "Update",
        link: ROUTES.UPDATE_REFERRAL,
    },
    referralAdd: {
        label: "Add",
        link: ROUTES.ADD_REFERRALS,
    },
    leadDetails: {
        label: "Lead Details",
        link: ROUTES.LEAD_DETAILS,
    },
    leadUpdate: {
        label: "Update",
        link: ROUTES.UPDATE_LEAD,
    },
    leadAdd: {
        label: "Add Lead",
        link: ROUTES.ADD_LEAD,
    }
};
