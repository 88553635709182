import {
  Routes,
  Route
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../App.css';
import { ROUTES } from '../contants/routes';
import { loadData } from "../utils/localStorage";

import Login from '../components/auth/login/login';
import ResetPassword from "../components/auth/resetPassword/resetPassword";
import ForgotPassword from '../components/auth/forgotPassword/forgotPassword';

import SalesDashboard from '../components/dashboard/salesDashboard';

import ReferralList from '../components/referrals/referralList/referralList';
import AddReferral from '../components/referrals/addReferral/addReferral';
import ReferralDetails from '../components/referrals/referralDetails/referralDetails';
import UpdateReferral from '../components/referrals/updateReferral/updateReferral';

import AddLead from '../components/Leads/addLead/addLead';
import LeadDetails from '../components/Leads/leadDetails/leadDetails';
import UpdateLead from '../components/Leads/updateLead/updateLead';


function App() {
  const accessToken = loadData("access")

  return (
    <Routes>
      <Route exact path={ROUTES.LOGIN} element={<Login />}></Route>
      <Route exact path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />}></Route>
      <Route exact path={ROUTES.RESET_PASSWORD + "/:otp/:email"} element={<ResetPassword />}></Route>

      {
        accessToken != null
        &&
        <>
          <Route exact path={ROUTES.DASHBOARD} element={<SalesDashboard />}></Route>

          <Route exact path={ROUTES.REFERRALS} element={<ReferralList />}></Route>
          <Route exact path={ROUTES.ADD_REFERRALS} element={<AddReferral />}></Route>
          <Route exact path={ROUTES.REFERRAL_DETAILS + "/:referralId"} element={<ReferralDetails />}></Route>
          <Route exact path={ROUTES.UPDATE_REFERRAL + "/:referralId"} element={<UpdateReferral />}></Route>

          <Route exact path={ROUTES.ADD_LEAD + "/:referralId"} element={<AddLead />}></Route>
          <Route exact path={ROUTES.REFERRAL_DETAILS + "/:referralId" + ROUTES.UPDATE_LEAD + "/:leadId"} element={<UpdateLead />}></Route>
          <Route exact path={ROUTES.REFERRAL_DETAILS + "/:referralId" + ROUTES.LEAD_DETAILS + "/:leadId"} element={<LeadDetails />}></Route>
        </>
      }

      <Route path="*" element={<Login />} />
    </Routes>
  );
}

export default App;
